<template>
  <div class="out">
    <div class="content">
      <CommonHeader :back="onBack">
        <template slot="title">
          <div class="index">
            <span>{{ imgIndex + 1 }}</span>
            /
            <span>{{ (value && value.length) || 0 }}</span>
          </div>
        </template>
        <template slot="right">
          <img
            v-if="!disabled"
            @click="clear"
            class="img"
            src="../../../assets/img/img-delete.png"
          />
        </template>
      </CommonHeader>
      <div style="height: calc(100% - 45px)">
        <swiper
          class="img-content"
          :options="swiperOption"
          @change="handleChange"
          :auto="0"
          :defaultIndex="+index"
          :show-indicators="false"
        >
          <swiper-slide v-for="(i, index) in value || []" :key="index">
            <p class="swiper-zoom-container">
              <!-- style=" width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;" -->
              <img :src="getImgURL(i, true)" />
            </p>
            <!-- <div class="img-1" :style="{backgroundImage:`url('${getImgURL(i)}')`}"/> -->
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import CommonHeader from "./Heard"
import { getImgURL } from "../../../utils/imgUrl"
import "swiper/dist/css/swiper.css"
import { swiper, swiperSlide } from "vue-awesome-swiper"
import { mapState, mapMutations } from "vuex"

let vm = null
export default {
  data() {
    return {
      imgIndex: null,
      swiperOption: {
        width: window.innerWidth,
        zoom: true,
        initialSlide: this.imgIndex,
        // loop: true,
        // slidesPerView: 'auto',
        // centeredSlides: true,
        on: {
          slideChange() {
            vm.imgIndex = this.activeIndex
          },
        },
      },
      popstateHandler: null,
    }
  },
  computed: {
    ...mapState("takePhoto", {
      value: (state) => state.currentItem.value,
      index: (state) => state.currentImgIndex,
    }),
    disabled() {
      return this.$route.query.disabled
    },
  },
  created() {
    vm = this
    this.imgIndex = this.index
    this.$set(this.swiperOption, "initialSlide", this.imgIndex)
  },
  mounted() {},
  destroyed() {},
  components: {
    CommonHeader,
    swiper,
    swiperSlide,
  },
  methods: {
    ...mapMutations("takePhoto", ["delectImg"]),
    close() {
      history.go(-1)
    },
    onBack() {
      this.close()
    },
    clear() {
      this.delectImg(this.imgIndex)
    },
    handleChange(val) {
      this.imgIndex = val
    },
    getImgURL,
  },
}
</script>
<style scoped>
.out {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .content {
    background: #000000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    & .img-content {
      flex: 1;
      height: 100%;
      padding: 8px 0 5px 0;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.img {
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
.index {
  font-size: 18px;
  transform: translateY(1px);
}
.img-1 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
